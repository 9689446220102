import React from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";

const SidebarListItem = ({
  open,
  handleOpen = () => {},
  icon: Icon,
  text,
  to,
  selected,
  subItems,
  permissions,
  expanded,
  level,
  action
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isChangeForm } = useAppSelector((state) => state.organizations);
  const { setPathForModalClosePage } = useAction();
  

  const handleChangePage = (to) => {
    if (action) {
      action();
    }
    if(isChangeForm) {
      setPathForModalClosePage(to);
      return;
    }
    navigate(to);
  };

  const handleCLickItem = () => {
    handleOpen();
    handleChangePage(to);
  };

  const getColor = ({ level, expanded, open }) => {
    if (selected && !expanded && level === 1) return "var(--black)";
    if (selected && level === 2) return "var(--black)";
    if (expanded && level === 1 && open) return "var(--additionalGrey)";

    return "var(--additionalGrey)";
  };

  return (
    <Accordion
      className={`sidebar-list ${open ? 'sidebar-opened-list' : 'sidebar-closed-list'}`}
      square={true}
      disableGutters={true}
      onClick={() =>
        expanded ? !open && handleCLickItem() : handleChangePage(to)
      }
    >
      <AccordionSummary
        className={`sidebar-list-row ${open ? 'sidebar-opened-list-row' : 'sidebar-closed-list-row'}`}
        expandIcon={
          expanded &&
          open && (
            <ExpandMoreIcon
              sx={{
                color:
                  selected && open ? "var(--black)" : "var(--additionalGrey)",
              }}
            />
          )
        }
        sx={{
          borderRight: selected
            ? open && expanded
              ? "4px solid var(--white)"
              : "4px solid var(--main)"
            : "4px solid var(--white)",
        }}
        focusVisibleClassName="test-focus"
      >
        <Box
          className="sidebar-list-row-content"
          sx={{
            justifyContent: open ? "initial" : "center",
            paddingLeft: !Icon && open ? "35px" : "initial",
            margin: open ? "inherit" : "0 auto",
          }}
        >
          {Icon && (
            <Box
              className="sidebar-list-row-content-icon"
              sx={{
                svg: {
                  fill: getColor({ level, expanded, open }),
                },
              }}
            >
              <Icon />
            </Box>
          )}
          <Typography
            className={`sidebar-list-row-content-text ${
              expanded ? "expended" : ""
            }`}
            sx={{
              display: open ? "block" : "none",
              paddingRight: "15px",
              color: getColor({ level, expanded, open }),
            }}
          >
            {text}
          </Typography>
        </Box>
      </AccordionSummary>
      {expanded && open && (
        <AccordionDetails sx={{ padding: 0, paddingBottom: "8px" }}>
          {subItems.filter((item) =>permissions.includes(item.name)).map(({ id, title, subItems, path, expanded, level }) => (
            <SidebarListItem
              key={id}
              text={title}
              to={path}
              open={true}
              selected={location?.pathname?.includes(path)}
              subItems={subItems}
              expanded={expanded}
              level={level}
              action={action}
            />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default SidebarListItem;
