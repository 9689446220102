import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Button, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "./styles/style.css";
import { sideBarOptionsNewVersion, BIG_SCREEN } from "../../../data";
import useScreenSize from "../../../hooks/useScreenSize";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from "../../../constants";
import { parseJsonToken } from "../../../helpers/helper";

import SidebarListItem from "./SidebarListItem";
import LogoIconSm from "../../components/icons/LogoIconSm";
import LogoLabelIcon from "../../components/icons/LogoLabelIcon";
import { ArrowIconLeftBlue } from "../../components/icons/ArrowIcon";

const SideBar = () => {
  const location = useLocation();
  const token = useAppSelector((state) => state.users.token);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const { roleId } = useAppSelector((state) => state.users.info);
  const { openSidebar } = useAppSelector((state) => state.settings);
  const { setOpenSidebar } = useAction();
  const { width } = useScreenSize();

  useEffect(() => {
    parseJsonToken({ token, setPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const options = sideBarOptionsNewVersion.filter((item) =>
    permissions.includes(item.name),
  );

  function hasEquipments(data) {
    return data.some((obj) => obj.name === "equipments");
  }

  const equipments = hasEquipments(options);

  useEffect(() => {
    setOpenSidebar(window.innerWidth > BIG_SCREEN);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = () => setOpenSidebar(!openSidebar);

  return (
    <Box
      className={`sidebar ${openSidebar ? "sidebar-opened" : `sidebar-closed`}`}
    >
      <IconButton
        onClick={toggleDrawer}
        className="sidebar-tablet-close-icon-button"
      >
        <CloseIcon />
      </IconButton>
      <Box
        className={`sidebar-logo ${openSidebar ? "sidebar-opened-logo" : `sidebar-closed-logo`}`}
        onClick={() =>
          roleId !== 4 && equipments
            ? navigate(PATHS.OBJECTS_MAP)
            : navigate(PATHS.LIST_TASKS)
        }
      >
        <Box
          className="logo-icon-sm"
          style={{ cursor: "pointer" }}
          sx={{ paddingTop: openSidebar ? "0" : "0" }}
          onClick={() =>
            roleId !== 4 && equipments
              ? navigate(PATHS.OBJECTS_MAP)
              : navigate(PATHS.LIST_TASKS)
          }
        >
          <LogoIconSm />
        </Box>
        <Box
          className="logo-label-icon"
          style={{ cursor: "pointer" }}
          sx={{ paddingTop: openSidebar ? "0" : "0" }}
          onClick={() =>
            roleId !== 4 && equipments
              ? navigate(PATHS.OBJECTS_MAP)
              : navigate(PATHS.LIST_TASKS)
          }
        >
          {openSidebar && <LogoLabelIcon />}
        </Box>
      </Box>
      <Button
        onClick={toggleDrawer}
        className="sidebar-arrow-button"
        sx={{
          transform: openSidebar ? "rotate(0deg)" : "rotate(-180deg)",
          minWidth: "0",
          borderRadius: "50% 50%",
          border: "1px solid #4339f270",
          left: openSidebar
            ? "calc(var(--widthFullSideBar) - var(--arrowBtn) / 2)"
            : "calc(var(--widthShortSideBar) - var(--arrowBtn) / 2)",
        }}
      >
        <ArrowIconLeftBlue />
      </Button>
      <Box className="scrolled-sidebar">
        {options.map(
          ({ id, title, subItems, icon, rootPath, path, expanded, level }) => (
            <SidebarListItem
              key={id}
              text={title}
              icon={icon}
              open={openSidebar}
              permissions={permissions}
              handleOpen={toggleDrawer}
              selected={
                location?.pathname?.includes(rootPath) ||
                location?.pathname?.includes(path)
              }
              to={path}
              subItems={subItems}
              expanded={expanded}
              level={level}
              action={ () => width <= 900 && toggleDrawer() }
            />
          ),
        )}
      </Box>
    </Box>
  );
};

export default SideBar;
