import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams,useSearchParams }from "react-router-dom";
import { dateFormat } from "../../../constants";

import EquipmentDialog from "../EquipmentList/EquipDialog";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TableBasic from "../../components/TableBasic";
import { useLazyGetRegulationsListQuery } from "../../../middleware/regulationsApi";
import EditButton from "../../components/EditButton";
import { ModalEquipSchema } from "../../components/Modals/ModalEquipSchema"
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from '../../../constants';
import { getColumns } from "./columns";
import { getDataToChart } from "../../../helpers/helper";
import { useAction } from "../../../hooks/actions";
import {
  useLazyGetFloorOptionsSelectByFacilityQuery,
} from "../../../middleware/facilitiesApi";
import { useGetUserGridSettingQuery,useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import {
  formFields,
  allColumns,
  getConfigFormModalSelect,
} from "./form-config";

const ReglamentList = () => {
  const [getParams, setSearchParams] = useSearchParams();
  const  objectId  =  getParams.has("objectId") ? getParams.get("objectId") : null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsErrorRequest,setSortParams } = useAction();
  const { regulations } = useAppSelector((state) => state.regulations);
  const { control, setValue, watch, handleSubmit, resetField } = useForm();
  const [pageNumber, changePageNumber] = useState(0);
  const [ranOnce, setRanOnce] = useState(false);
  const [ranOnce2, setRanOnce2] = useState(false);
  const [openSchema, setOpenSchema] = useState(false);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [searchPlume, setSearchPlume] = useState("");
  const [searchIntegrationNumber, setSearchIntegrationNumber] = useState("");
  const [searchNumber, setSearchNumber] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);

  const [searchequipmentCount, setsearchequipmentCount] = useState("");
  const [searchStandardName, setsearchStandardName] = useState("");
  const [searchPeriodName, setsearchPeriodName] = useState("");
  const [searchEquipmentName, setsearchEquipmentName] = useState("");
  const [searchRegulationName, setsearchRegulationName] = useState("");
  const [searchWorkDetailes, setsearchWorkDetailes] = useState("");
  const [searchWorkscopeName, setsearchWorkscopeName] = useState("");


  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({gridSettingName:"EquipmentList"});

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
  useLazyGetUserGridSettingQuery();
  const [getRegulationsTable, { isLoading, error }] =
  useLazyGetRegulationsListQuery();

  const [getFloorOptions, { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions }] =
    useLazyGetFloorOptionsSelectByFacilityQuery();

  useEffect(() => {
    let filtersRes = {}
    let res = ""
    if (params) {
      Object.keys(params)?.forEach(key => {
        if (params[key] != "все" && params[key] != "")
          if (key == "equipmentKindId") {
            if(JSON.parse(params[key])?.isType){
              filtersRes["EquipmentKindId"] = Number(JSON.parse(params[key]).id.replace("parent","")) 
            }else{
              filtersRes["EquipmentTypeId"] = JSON.parse(params[key]).id
            }
          } else if (key == "lifeTimeDate" || key == "workingCorrectly") {

            filtersRes[key] = params[key] == "true" ? true : false
          } else {
            filtersRes[key] = params[key]
          }

      });
    }
    res = "&" + Object.keys(filtersRes)
      .map((key) => `${key}=${filtersRes[key]}`)
      .join("&")
    changePageNumber(0)
    getRegulationsTable(
      {
        PageNumber: 0,
        filters: res
      });
  }, [params]);
  useEffect(() => {
    let filtersRes = {}
    let res = ""
    if (params) {
      Object.keys(params)?.forEach(key => {
        if (params[key] != "все" && params[key] != "")
          if (key == "equipmentKindId") {
            filtersRes[key] = JSON.parse(params[key]).id
          } else if (key == "explotationExceeded" || key == "workingCorrectly") {

            filtersRes[key] = params[key] == "true" ? true : false
          } else {
            filtersRes[key] = params[key]
          }

      });
    }
    res = "&" + Object.keys(filtersRes)
      .map((key) => `${key}=${filtersRes[key]}`)
      .join("&")

    getRegulationsTable(
      {
        PageNumber: pageNumber,
        filters: res
      });
  }, [pageNumber]);


  const facilityId = watch(`${formFields.searchParams}.${formFields.protectionObjectId}`);
 
  useEffect(() => {
    setValue(`${formFields.searchParams}.equipmentCount`, searchequipmentCount);
  }, [searchequipmentCount, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.StandardName`, searchStandardName);
  }, [searchStandardName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.PeriodName`, searchPeriodName);
  }, [searchPeriodName, setValue]); 

  useEffect(() => {
    setValue(`${formFields.searchParams}.EquipmentName`, searchEquipmentName);
  }, [searchEquipmentName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.RegulationName`, searchRegulationName);
  }, [searchRegulationName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.WorkRequirementsDetails`, searchWorkDetailes);
  }, [searchWorkDetailes, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.WorkscopeName`, searchWorkscopeName);
  }, [searchWorkscopeName, setValue]);

  useEffect(() => {
    if (facilityId) {
      if(objectId && !ranOnce2){
        setRanOnce2(true)
        setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`,objectId)
      }
      if (facilityId == "все") {
        setValue(`${formFields.searchParams}.${formFields.floorId}`, "все")
      }
      else {

      }
    }

  }, [facilityId,objectId, getFloorOptions, setValue]);

  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();
  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        searchequipmentCount,
        setsearchequipmentCount,
        objectId,
        searchStandardName,
        setsearchStandardName,
        searchPeriodName,
        setsearchPeriodName,
        searchEquipmentName,
        setsearchEquipmentName,
        searchRegulationName,
        setsearchRegulationName,
        searchWorkDetailes,
        setsearchWorkDetailes,
        searchWorkscopeName,
        setsearchWorkscopeName,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );
  const rows = useMemo(() => {
    const { data } = regulations;
    return data?.length
      ? data.map(({ id, equipmentCount,protectionObjectId,regulationId,equipmentKindId, equipmentKindName,regulationDescription,periodName, equipmentName, equipmentTypeName,equipmentTypeId,equipmentGroup, isCustom,protectionObjectName, regulationName, workingProperly, standardName, standardParagraphName, workRequirementsDetails,workscopeName }) => {
        
        return {
          id, 
          equipmentCount, 
          equipmentKindName: `${equipmentKindName}, ${equipmentTypeName}`, 
          equipmentName:{
            id,
            name:equipmentName,
            action: () => navigate(PATHS.LIST_EQUIPMENT + `?equipmentGroupId=${equipmentGroup}`),
          }, 
          equipmentTypeName, 
          isCustom:isCustom ? "Пользователь" : "Стандарт",
          regulationDescription,
          periodName,
          protectionObjectName, 
          regulationName,
          workingProperly, 
          standardName, 
          standardParagraphName, 
          workRequirementsDetails,
          workscopeName,
         edit: <EditButton handleClick={() => navigate(PATHS.EDIT_EQUIPMENT_REGLAMENTS+`?id=${id}&regulationId=${regulationId}&protectionObjectId=${protectionObjectId}&equipmentKindId=${equipmentKindId}&equipmentTypeId=${equipmentTypeId}&equipmentGroup=${equipmentGroup}`)} />,
        }
       /* return {
          id,
          integrationNumber,
          number,
          name:{
            id,
            name,
            action: () => handleOpenEquipment(id),
          },
          plume,
          floorNumber: {
            id,
            floorNumber,
            action: () => handleOpenSchema(id),
          },
          protectionObjectName,
          lifeTimeDate: <Typography sx={{ color: lefeTimeOutdated ? "red" : "black", fontSize: "14px !important" }}>{lifeTimeDate ? dayjs(lifeTimeDate).format(dateFormat) : ""}</Typography>,
          lastServiceDate: lastServiceDate ? <Typography sx={{ color: "green", fontSize: "14px !important" }}>{dayjs(lastServiceDate).format(dateFormat)}</Typography> : <></>,
          workingProperly: workingProperly ? <Typography sx={{ color: "green", fontSize: "14px !important" }}>Да</Typography> : <Typography sx={{ color: "red", fontSize: "14px !important" }}>Нет</Typography>,
          equipmentKindName,
          edit: enableForEdit ? <EditButton handleClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))} /> : <></>,
          tasks: getDataToChart({ jobChart, action: `?facilityName=${protectionObjectId}&equipmentId=${id}&equipmentName=${name}` }),
        };*/
      })
      : [];
  }, [regulations]);

  const handleOpenSchema = (id) => {
    setOpenSchema(true);
    setSelectedEquipId(id);
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };
  
  useEffect(() => {
    if (!ranOnce) {

      setRanOnce(true);
      setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, "все")
      setValue(`${formFields.searchParams}.${formFields.floorId}`, "все")
      setValue(`${formFields.searchParams}.${formFields.equipmentKindId}`, "все")
    }
  }, []);
  const recordsCount = useMemo(
    () => regulations && regulations?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regulations]
  );
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };
  return (
    <Box className="template_page">
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => getRegulationsTable({ pageNumber })}

        />
      )}
      {openSchema && (
        <ModalEquipSchema
          open={openSchema}
          setOpen={setOpenSchema}
          control={control}
          setValue={setValue}
          equipId={selectedEquipId}
          refetch={() => getRegulationsTable({ pageNumber })}

        />
      )}
      <Box className="template_page-header">
        <Typography className="title-page">
          {t("title_page:equip_reglaments")}
        </Typography>
        <Box>
          <Button
                className="btn btn-outlined-primary"
                variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => {
              navigate(PATHS.ADD_EQUIPMENT_REGLAMENTS);
            }}
          >
            {t("button:add_regulations")}
          </Button>
        {/*  <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "49px", marginRight: "16.8px" }}
          >
            {t("button:customize_fields_to_display")}
          </Button> */}
        </Box>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => { }}
        withSearchField={false}
        isLoading={isLoading}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={"60vh"}
        maxHeight={"70vh"}
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({gridSettingName:"EquipmentList"})}
        nameStorage={"EquipmentList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};

export default ReglamentList;
