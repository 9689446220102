import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";

import "./styles/style.css";
import {
  allColumns,
  
  formFields,
  getConfigFormModalSelect,
} from "./table-config";
import { useAction } from "../../../hooks/actions";
import { getColumns } from "./columns";
import { useAppSelector } from "../../../hooks/redux";
import { useLazyGetLogOfWorkDoneOnTasksQuery, useLazyPrintLogsListQuery } from "../../../middleware/logsApi";
import { getBgColor } from "./helpers";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import FormItem from "../../components/FormItem";
import SelectSimple from "../../components/SelectSimple";
import TableBasic from "../../components/TableBasic";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';
import TaskDialog from "../TasksList/TaskDialog/TaskDialog";

export const MagazineFinishedTasks = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues } = useForm({ mode: "onChange",});

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const { allLogOfWorkDoneOnTasks } = useAppSelector((state) => state.logs);
  const { setIsErrorRequest } = useAction();
  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [facilities, setFacilities] = useState(""); //mock
  const [pageNumber, changePageNumber] = useState(0);
 // const [searchJobname, setsearchJobname] = useState("");
 // const [searchValue, changeSearchValue] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [jobName, setJobName] = useState("");
  const [searchworkScopeName, setsearchworkScopeName] = useState("");
  const [searchworkScopeDetails, setsearchworkScopeDetails] = useState("");
  const [equipName, setequipName] = useState("");
  const [roomPlume, setroomPlume] = useState("");
  const [executiveUser, setexecutiveUser] = useState("");
  const [responsibleUser, setresponsibleUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false)


  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "CompletedJobJournal" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();
  const [getLogOfWorkDoneOnTasks, { error, isLoading }] =
  useLazyGetLogOfWorkDoneOnTasksQuery();
  const [printLogsList, { errorPrint, isLoadingPrint }] =
  useLazyPrintLogsListQuery();

  const handleOpenTaskDialogState = (id) => {
    setOpenTaskDialog(true);
    setSelectedTaskId(id);
  };

  const rows = useMemo(() => {
    const { data } = allLogOfWorkDoneOnTasks || {};

    return data?.length
      ? data.map(
        ({
          workScopeName,
          completedAt,
          roomAddressPlume,
          executiveUser,
          facilityName,
          isExecutiveUserSign,
          isVerifyingUserSign,
          jobDescription,
          jobId,
          jobName,
          responsibleCustomer,
          workScopeDetails,
          responsibleCustomerSignature,
          executiveUserSignature,
          jobNumber = "",
          equipmentId,
          equipmentName = "",
        }) => {

          return {

            jobName: (jobId && jobId != null)?(
              <Button
                className="table-row-cell-link"
                sx={{ verticalAlign: "initial" }}
                onClick={() => handleOpenTaskDialogState(jobId)}
              >
                {`${jobName}`}
              </Button>
            ):(
              <></>
            ),
            workScopeName,
            workScopeDetails,
            completedAt,
            roomAddressPlume: roomAddressPlume || t("label:doesntHave"),
            executiveUser,
            responsibleCustomer,
            facilityName: facilityName || t("label:doesntHave"),
            isExecutiveUserSign,
            isVerifyingUserSign,
            jobDescription,
            responsibleCustomerSignature,
            executiveUserSignature,
            equipmentName: (
              <Button
                className="table-row-cell-link"
                sx={{ verticalAlign: "initial", textAlign: "left" }}
                onClick={() => handleOpenEquipment(equipmentId)}
              >
                {equipmentName}
              </Button>
            )
          }
        }
      )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLogOfWorkDoneOnTasks]);


  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        jobName,
        setJobName,
        searchworkScopeName,
        setsearchworkScopeName,
        searchworkScopeDetails,
        setsearchworkScopeDetails,
        equipName,
        setequipName,
        roomPlume,
        setroomPlume,
        executiveUser,
        facilities,
        setFacilities,
        setexecutiveUser,
        responsibleUser,
        setresponsibleUser,

      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const recordsCount = useMemo(
    () => allLogOfWorkDoneOnTasks && allLogOfWorkDoneOnTasks?.recordsCount,
    [allLogOfWorkDoneOnTasks]
  );

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.workScopeName}`,
      searchworkScopeName
    );
  }, [searchworkScopeName, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.workScopeDetails}`,
      searchworkScopeDetails
    );
  }, [searchworkScopeDetails, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.TaskName}`,
      jobName
    );
  }, [jobName, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.RoomPlume`,
      roomPlume
    );
  }, [roomPlume, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.EquipmentNameAndNumber`,
      equipName
    );
  }, [equipName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ExecutiveUser`,
      executiveUser
    );
  }, [executiveUser, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ResponsibleCustomerUser`,
      responsibleUser
    );
  }, [responsibleUser, setValue]);

  useEffect(() => {
    const createdDate = getValues(
      `${formFields.searchParams}.CompletedDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)
    const facility = (facilId == "все" || !facilId) ? {FacilityId:""} : {FacilityId:facilId}  
    if (params?.CompletedDateRange)
      delete params.CompletedDateRange
    if (params?.FacilityId)
      delete params.FacilityId
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["CompletedDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["CompletedDateRange.MinValue"] = createdDate.MinValue
    }
    changePageNumber(0)
    getLogOfWorkDoneOnTasks({
      ...dd,
      ...facility,
      ...params,
      pageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const createdDate = getValues(
      `${formFields.searchParams}.CompletedDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)
    const facility = (facilId == "все" || !facilId) ? {FacilityId:""} : {FacilityId:facilId}  
    if (params?.CompletedDateRange)
      delete params.CompletedDateRange
    if (params?.FacilityId)
      delete params.FacilityId
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["CompletedDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["CompletedDateRange.MinValue"] = createdDate.MinValue
    }
    getLogOfWorkDoneOnTasks({
      ...dd,
      ...facility,
      ...params,
      pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);


  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const base64toPDF = (name, content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false)
  }
  const printBase64 = (content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blob = new Blob([bufferArray], { type: 'application/pdf' }); //this make the magic
    var blobURL = URL.createObjectURL(blob);

    let iframe = document.createElement('iframe'); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    setPrintLoading(false)
  }

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  const printLogs = (isPrint) => {
    if (isPrint)
      setPrintLoading(true);
    else
      setLoading(true);
    const createdDate = getValues(
      `${formFields.searchParams}.CompletedDateRange`
    );
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)
    const facility = (facilId == "все" || !facilId) ? {FacilityId:""} : {FacilityId:facilId}  
    if (params?.CompletedDateRange)
      delete params.CompletedDateRange
    if (params?.FacilityId)
      delete params.FacilityId
    let dd = {}
    if (createdDate) {
      if (createdDate.MaxValue != "")
        dd["CompletedDateRange.MaxValue"] = createdDate.MaxValue
      if (createdDate.MinValue != "")
        dd["CompletedDateRange.MinValue"] = createdDate.MinValue
    }
    printLogsList({
      ...dd,
      ...params,
      ...facility,
      pageNumber,
    }).then((data) => {
      const res = data.data
      if (isPrint)
        printBase64(data.data.content)
      else
        base64toPDF(data.data.name, data.data.content)
    });
  }

  return (
    <Box className="template_page magazine_events">
      <Box className="legal-entity-header">
        <Typography className="title-page">
          {t("title_page:magazines_finished_tasks")}
        </Typography>
        <Box sx={{ display: "flex" }}>
          {printloading ?
            <Box className="full_screen_icon-wrapper">
              <CircularProgress className="full_screen_icon" sx={{
                width: "30px",
                height: "30px",
                marginRight: "20px",
                marginTop: "5px"
              }}
              />
            </Box>
            :
            <Box className="full_screen_icon-wrapper">
              <PrintIcon
                className="full_screen_icon"
                onClick={() => printLogs(true)}
                sx={{
                  width: "30px",
                  height: "30px",
                  marginRight: "20px",
                  marginTop: "5px",
                  border:"0px !important"
                }}
              />
            </Box>
          }
          <Button
             className="btn btn-outlined-primary magazine_events-select_container-btn"
             variant="outlined"
            onClick={() => printLogs(false)}
            disabled={loading}
            sx={{ height: "49px", marginRight: "16.8px" }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : t("button:create_act_pdf")}
          </Button>
          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "49px", marginRight: "16.8px" }}
          >
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>

      <form>
       {/* <Box className="magazine_events-select_container">
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.eventsFilter}`}
            label={t("label:events")}
            type={"select-text"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimple {...props} />
              </Box>
            )}
          />
        </Box>
            */}        
        <TableBasic
          columns={columns}
          rows={rows}
          handleClickCell={() => { }}
          withSearchField={false}
          isLoading={isLoading}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
          count={recordsCount}
        //  getCustomBgColorRow={getBgColor(bgColors)}
          minHeight={"49vh"}
          maxHeight={"70vh"}
        />
      </form>

      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "CompletedJobJournal" })}
        nameStorage={"CompletedJobJournal"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />

      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          setOpen={setOpenTaskDialog}
          taskId={selectedTaskId}
        />
      )}

      {openEquipDialog && 
        <EquipmentDialog
        open={openEquipDialog}
        setOpen={setopenEquipDialog}
        equipId={selectedEquipId}
      />
      }
      
    </Box>
  );
};

