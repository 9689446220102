import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";

import "./styles/style.css";
import {
  allColumns,

  formFieldsTable as formFields,
  getConfigFormModalSelect,
} from "./form-config";
import { useNavigate } from "react-router";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import ObjectDialog from "../ObjectsList/ObjectDialog";
import MyOrganizationDetails from "../MyOrganizationDetails";
import { EmployeeDialog } from "./EmployeeDialog";
import { getColumns } from "./columns";
import { useAppSelector } from "../../../hooks/redux";
import EditButton from "../../components/EditButton";
import { useLazyGetAllEmployeesQuery } from "../../../middleware/employeeApi";
import TableBasic from "../../components/TableBasic";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery, useConfirmVerificationMutation } from "../../../middleware/usersApi";
import { getDataToChart } from "../../../helpers/helper";

export const EmployeeList = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues } = useForm({
    mode: "onChange",

  });
  const navigate = useNavigate();
  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const { allEmployees } = useAppSelector((state) => state.employee);
  const { setIsErrorRequest } = useAction();

  const [facilities, setFacilities] = useState(""); //mock
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [jobName, setJobName] = useState("");
  const [searchworkScopeName, setsearchworkScopeName] = useState("");
  const [searchworkScopeDetails, setsearchworkScopeDetails] = useState("");
  const [equipName, setequipName] = useState("");
  const [roomPlume, setroomPlume] = useState("");
  const [executiveUser, setexecutiveUser] = useState("");
  const [responsibleUser, setresponsibleUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false)


  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [searchValue, changeSearchValue] = useState("");
  const [searchName, setsearchName] = useState("");
  const [organizationSearch, setorganizationSearch] = useState("");
  const [employeePosition, setemployeePosition] = useState("");
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [searchRole, setsearchRole] = useState("все");
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [pageNumber, changePageNumber] = useState(0);
  const [organizationTypeId, setOrganizationTypeId] = useState("все");
  const [activeValue, changeactiveValue] = useState("все");
  const [verifiedValue, changeverifiedValue] = useState("все");

  const [openDiteilsPopUp, setOpenDiteilsPopUp] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedOrgTypeId, setSelectedOrgTypeId] = useState(null);


  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "EmployeeList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();
  const [getAllEmployees, { isLoading, refetch: refetchEmployee, error }] = useLazyGetAllEmployeesQuery();
  const [verifyUser, { error: errorverify, isSuccess: isSuccessverify }] =
    useConfirmVerificationMutation();

  const handleOpenTaskDialogState = (id) => {
    setOpenTaskDialog(true);
    setSelectedTaskId(id);
  };
  const handleOpenEmployeeDialogState = (id) => {
    if (!openEmployeeDialog) {
      setOpenEmployeeDialog(true);
      setSelectedEmployeeId(id);
      return;
    }
    setOpenEmployeeDialog(false);
    setSelectedEmployeeId(null);
  };

  const handleOpenObjectDialogState = (id) => {
    if (!openObjectDialog) {
      setOpenObjectDialog(true);
      setSelectedObjectId(id);
      return;
    }
    setOpenObjectDialog(false);
    setSelectedObjectId(null);
  };
  const handleOpenDetails = ({ id, typeId }) => {
    setSelectedId(id);
    setSelectedOrgTypeId(typeId);
    setOpenDiteilsPopUp(!!id);
  };
  const handleLink = (id) =>
    navigate(PATHS.GET_EDIT_EMPLOYEE(id));
  const verifyEmployee = (id) => {
    verifyUser({ id: id }).then((result) => {
      if ("error" in result) {
        return;
      }
      const role = searchRole
      const typeId = organizationTypeId
      const Active = activeValue
      const Verify = verifiedValue
      const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)
      const equipKinds = getValues(`${formFields.searchParams}.equipKinds`)
    
      const EquipmentKindId = (equipKinds == "все" || !equipKinds) ? { EquipmentKindId: "" } : { EquipmentKindId: equipKinds }
      const facility = (facilId == "все" || !facilId) ? { ProtectionObjectId: "" } : { ProtectionObjectId: facilId }
      const RoleName = (role == "все" || !role) ? { RoleName: "" } : { RoleName: role }
      const OrganizationTypeId = (typeId == "все" || !typeId) ? { OrganizationTypeId: "" } : { OrganizationTypeId: typeId }
      const IsActive = (Active == "все" || !Active) ? { IsActive: "" } : { IsActive: Active }
      const IsVerified = (Verify == "все" || !Verify) ? { IsVerified: "" } : { IsVerified: Verify }
      if (params?.FacilityId)
        delete params.FacilityId
      getAllEmployees({
        ...facility,
        ...RoleName,
        ...EquipmentKindId,
        ...OrganizationTypeId,
        ...IsActive,
        ...IsVerified,
        ...params,
        pageNumber: pageNumber,
      });
      //getAllEmployees({ FullNameOrLoginOrEmail: searchValue, searchValue: "", pageNumber, IsActive: activeValue == "все" ? "" : activeValue, IsVerified: verifiedValue == "все" ? "" : verifiedValue, organizationTypeId: organizationTypeId == t("tableHeader:type") ? "" : organizationTypeId });
    })
  }
  const rows = useMemo(() => {
    const { data } = allEmployees || {};
    return data?.length
      ? data.map(
        ({
          employeeId,
          avatarLink,
          employeeEquipmentKinds,
          employeeFacilities,
          enableForEdit,
          employeeFullName,
          employeeEmail,
          employeeLogin,
          employeePosition,
          organizationType,
          employeeRoleName,
          organizations,
          isActive,
          enableConfirmVerification,
          jobChart,
          isVerified
        }) => ({
          employeeId,
          avatarLink,
          employeeFacilities: employeeFacilities?.length
            ? employeeFacilities.map(({ id, name }) => ({
              id,
              name,
              action: () => handleOpenObjectDialogState(id),
            }))
            : [],
          employeeFullName: {
            name: employeeFullName,
            isActive: isActive,
            action: () =>
              employeeId && handleOpenEmployeeDialogState(employeeId),
          },
          employeePosition,
          organizationType,
          employeeEquipmentKinds,
          employeeEmail,
          employeeLogin,
          employeeRoleName,
          organizations: organizations?.length
            ? organizations.map(({ id, name, typeId }) => ({
              id,
              name,
              action: () => handleOpenDetails({ id, typeId }),
            }))
            : [],
          edit: enableForEdit ? <EditButton handleClick={() => handleLink(employeeId)} /> : <></>,
          isActive: isActive ? isVerified ?"Активен" : "Активен/требуется подтверждение" : "Заблокирован",
          isVerified: (enableConfirmVerification && !isVerified) ?
            <Button
              className="btn btn-primary-fill"
              variant="contained"

              onClick={() => verifyEmployee(employeeId)}>
              {t("button:confirm")}
            </Button>
            : ((!enableConfirmVerification && isVerified) || (enableConfirmVerification && isVerified)) ?
              "Подтвержден" :
              "Не подтвержден",
          isActiveBool: isActive,
          tasks: getDataToChart({ jobChart, action: `?executiveUserId=${employeeId}&executiveUser=${employeeFullName}` }),
          isVerifiedBool: isVerified
        })
      )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployees]);


  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();
  const handleChange = (value) => {
    setOrganizationTypeId(value);
  };

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        organizationTypeId,
        handleChange,
        activeValue,
        changeactiveValue,
        verifiedValue,
        changeverifiedValue,
        searchName,
        setsearchName,
        organizationSearch,
        setorganizationSearch,
        employeePosition,
        setemployeePosition,
        login,
        setLogin,
        email,
        setEmail,
        searchRole,
        setsearchRole,
        rows
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const recordsCount = useMemo(
    () => allEmployees && allEmployees?.recordsCount,
    [allEmployees]
  );

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.employeeFullName}`,
      searchName
    );
  }, [searchName, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.employeeLogin}`,
      login
    );
  }, [login, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.employeeEmail}`,
      email
    );
  }, [email, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.employeePosition}`,
      employeePosition
    );
  }, [employeePosition, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.organizations}`,
      organizationSearch
    );
  }, [organizationSearch, setValue]);
  

  useEffect(() => {
    if(!getValues(`${formFields.searchParams}.FacilityId`)){
      setValue(`${formFields.searchParams}.FacilityId`,"все")
    }
    const role = searchRole
    const typeId = organizationTypeId
    const Active = activeValue
    const Verify = verifiedValue
    const facilId = getValues(`${formFields.searchParams}.FacilityId`)
    const equipKinds = getValues(`${formFields.searchParams}.equipKinds`)
    console.log("params",params)
    const EquipmentKindId = (equipKinds == "все" || !equipKinds) ? { EquipmentKindId: "" } : { EquipmentKindId: equipKinds }
    const facility = (facilId == "все" || !facilId) ? { ProtectionObjectId: "" } : { ProtectionObjectId: facilId }
    const RoleName = (role == "все" || !role) ? { RoleName: "" } : { RoleName: role }
    const OrganizationTypeId = (typeId == "все" || !typeId) ? { OrganizationTypeId: "" } : { OrganizationTypeId: typeId }
    const IsActive = (Active == "все" || !Active) ? { IsActive: "" } : { IsActive: Active }
    const IsVerified = (Verify == "все" || !Verify) ? { IsVerified: "" } : { IsVerified: Verify }
    if (params?.FacilityId)
      delete params.FacilityId
    changePageNumber(0)
    getAllEmployees({
      ...facility,
      ...EquipmentKindId,
      ...RoleName,
      ...OrganizationTypeId,
      ...IsActive,
      ...IsVerified,
      ...params,
      pageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params,searchRole,organizationTypeId,activeValue,verifiedValue]);

  useEffect(() => {
    const role = searchRole
    const typeId = organizationTypeId
    const Active = activeValue
    const Verify = verifiedValue
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`)
    const equipKinds = getValues(`${formFields.searchParams}.equipKinds`)
    
    const EquipmentKindId = (equipKinds == "все" || !equipKinds) ? { EquipmentKindId: "" } : { EquipmentKindId: equipKinds }
    const facility = (facilId == "все" || !facilId) ? { ProtectionObjectId: "" } : { ProtectionObjectId: facilId }
    const RoleName = (role == "все" || !role) ? { RoleName: "" } : { RoleName: role }
    const OrganizationTypeId = (typeId == "все" || !typeId) ? { OrganizationTypeId: "" } : { OrganizationTypeId: typeId }
    const IsActive = (Active == "все" || !Active) ? { IsActive: "" } : { IsActive: Active }
    const IsVerified = (Verify == "все" || !Verify) ? { IsVerified: "" } : { IsVerified: Verify }
    if (params?.FacilityId)
      delete params.FacilityId
    getAllEmployees({
      ...facility,
      ...RoleName,
      ...OrganizationTypeId,
      ...EquipmentKindId,
      ...IsActive,
      ...IsVerified,
      ...params,
      pageNumber: pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);


  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const getBgColor = (row, index) => {
    const { isActiveBool, isVerifiedBool } = row || {};
    return ((!isActiveBool && !isVerifiedBool) || (!isActiveBool && isVerifiedBool))
      ? "rgba(255,0,0,0.6)"
      : (isActiveBool && !isVerifiedBool)
        ? "rgba(255,255,0,0.6)"
        : "rgba(51,255,51,0.6)";
  };

  return (
    <Box className="template_page magazine_events">
      {openObjectDialog && (
        <ObjectDialog
          open={openObjectDialog}
          onClose={handleOpenObjectDialogState}
          objectId={selectedObjectId}
        />
      )}

      {openEmployeeDialog && (
        <EmployeeDialog
          open={openEmployeeDialog}
          onClose={handleOpenEmployeeDialogState}
          employeeId={selectedEmployeeId}
        />
      )}

      <Box className="template_page-header">
        <Typography className="template_page-header-title">
          {t("title_page:employees")}
        </Typography>
        <Box>
          <Button
               className="btn btn-outlined-primary"
               variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => {
              navigate(PATHS.ADD_EMPLOYEE);
            }}
          >
            {t("button:add_employee")}
          </Button>

          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>

      <form>
        {/* <Box className="magazine_events-select_container">
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.eventsFilter}`}
            label={t("label:events")}
            type={"select-text"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimple {...props} />
              </Box>
            )}
          />
        </Box>
            */}
        <TableBasic
          columns={columns}
          rows={rows}
          handleClickCell={() => { }}
          withSearchField={false}
          isLoading={isLoading}
          pageNumber={pageNumber}
         // getCustomBgColorRow={getBgColor}
          changePageNumber={changePageNumber}
          count={recordsCount}
          //  getCustomBgColorRow={getBgColor(bgColors)}
          minHeight={"49vh"}
          maxHeight={"70vh"}
        />
      </form>
      <MyOrganizationDetails
        open={openDiteilsPopUp}
        setOpen={setOpenDiteilsPopUp}
        id={selectedId}
        typeId={selectedOrgTypeId}
      />

      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "EmployeeList" })}
        nameStorage={"EmployeeList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};

