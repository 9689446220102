import React, { useMemo, useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { useLazyGetAllOrganizationsQuery } from "../../../middleware/apiOrganizations";
import { useForm, useWatch } from "react-hook-form";
import { getRowsData } from "./helpers";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TableBasic from "../../components/TableBasic";
import MyOrganizationDetails from "../MyOrganizationDetails";
import { getColumns } from "./columns";
import { useGetUserGridSettingQuery,useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import {
  formFields,
  allColumns,
  getConfigFormModalSelect,
} from "./form-config";

const MyOrganizations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { allOrganizations } = useAppSelector((state) => state.organizations);
  const { setIsErrorRequest, setSortParams } = useAction();
  const { control, setValue, watch, handleSubmit, resetField } = useForm();
  const [selectedId, setSelectedId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [openDiteilsPopUp, setOpenDiteilsPopUp] = useState(false);
  const [orgTypeFilter, setOrgTypeFilter] = useState(t("tableHeader:organizationType"));
  const [pageNumber, changePageNumber] = useState(0);
  const [searchInn, setsearchInn] = useState("");
  const [searchName, setsearchName] = useState("");
  const [searchAddress, setsearchAddress] = useState("");
  const [searchValue, changeSearchValue] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({gridSettingName:"OrganizationList"});

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
  useLazyGetUserGridSettingQuery();
  const [getOrganizationsTable, { error, isLoading }] =
    useLazyGetAllOrganizationsQuery();

  const handleChange = (value) => {
    setOrgTypeFilter(value);
  };

  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        orgTypeFilter,
        searchInn,
        setsearchInn,
        searchName, 
        setsearchName,
        searchAddress, 
        setsearchAddress,
        handleChange
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );


  const handleOpenDetails = ({ id, typeId }) => {
    setSelectedId(id);
    setTypeId(typeId);
    setOpenDiteilsPopUp(!!id);
  };

  const handleLink = (id, typeId) =>
    navigate(PATHS.GET_EDIT_MY_ORGANIZATION(id, typeId));

  const rows = useMemo(
    () => {
      const { data } = allOrganizations;
      return getRowsData(data, handleLink, handleOpenDetails);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allOrganizations]
  );

  const recordsCount = useMemo(
    () => allOrganizations && allOrganizations?.recordsCount,
    [allOrganizations]
  );

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    //const emptyDTO = `&AddressFilter.Town=null&AddressFilter.Id=null&AddressFilter.Subdivision=null&AddressFilter.Region=null&AddressFilter.Extension=null&AddressFilter.Coordinates.Latitude=null&AddressFilter.Coordinates.Longitude=null`
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building //+emptyDTO
    changePageNumber(0)
    getOrganizationsTable({ searchValue, pageNumber: 0,InnNumber:searchInn,address:address,name:searchName, orgTypeFilter: orgTypeFilter == t("tableHeader:organizationType") ? "" : orgTypeFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue,searchInn,searchAddress,searchName, orgTypeFilter]);

  useEffect(() => {
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : ""
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : ""
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : ""
    const address = city + street + building
    getOrganizationsTable({ searchValue,InnNumber:searchInn,address:address,name:searchName,  pageNumber, orgTypeFilter: orgTypeFilter == t("tableHeader:organizationType") ? "" : orgTypeFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  return (
    <Box className="legal-entity">
      <Box className="legal-entity-header">
        <Typography className="title-page">
          {t("title_page:legal_entities")}
        </Typography>
        <Box>
          <Button
              className="btn btn-outlined-primary"
              variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => {
              navigate(PATHS.ADD_MY_ORGANIZATION);
            }}
          >
            {t("button:add_organization")}
          </Button>

          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "49px", marginRight: "16.8px" }}
          >
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>

      <TableBasic
        columns={columns}
        rows={rows || []}
        handleClickCell={() => { }}
      //  searchValue={searchValue}
      //  changeSearchValue={changeSearchValue}
        pageNumber={pageNumber}
        withSearchField={false}
        changePageNumber={changePageNumber}
      //  placeholder={t("placeholders:searchByNameOrNumber")}
        isLoading={isLoading}
        count={recordsCount}
        minHeight="52vh"
        maxHeight="60vh"
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({gridSettingName:"OrganizationList"})}
        nameStorage={"OrganizationList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />

      <MyOrganizationDetails
        open={openDiteilsPopUp}
        setOpen={setOpenDiteilsPopUp}
        id={selectedId}
        typeId={typeId}
      />
    </Box>
  );
};

export default MyOrganizations;
