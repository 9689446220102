import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { dateFormat } from "../../../constants";

import EquipmentDialog from "./EquipDialog";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TableBasic from "../../components/TableBasic";
import { useLazyGetEquipmentListQuery, useLazyPrintEquipmentListQuery } from "../../../middleware/equipmentsApi";
import EditButton from "../../components/EditButton";
import { ModalEquipSchema } from "../../components/Modals/ModalEquipSchema"
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from '../../../constants';
import { getColumns } from "./columns";
import { getDataToChart } from "../../../helpers/helper";
import { useAction } from "../../../hooks/actions";
import {
  useLazyGetFloorOptionsSelectByFacilityQuery,
} from "../../../middleware/facilitiesApi";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import {
  formFields,
  allColumns,
  getConfigFormModalSelect,
} from "./form-config";
import { CircularProgress } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';


const EquipmentList = () => {
  const [getParams, setSearchParams] = useSearchParams();
  const objectId = getParams.has("objectId") ? getParams.get("objectId") : null;
  const equipmentGroupId = getParams.has("equipmentGroupId") ? getParams.get("equipmentGroupId") : null;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsErrorRequest, setSortParams } = useAction();
  const { equipments } = useAppSelector((state) => state.equipments);
  const { control, setValue, watch, handleSubmit, resetField } = useForm();
  const [pageNumber, changePageNumber] = useState(0);
  const [ranOnce, setRanOnce] = useState(false);
  const [ranOnce2, setRanOnce2] = useState(false);
  const [openSchema, setOpenSchema] = useState(false);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [searchPlume, setSearchPlume] = useState("");
  const [searchIntegrationNumber, setSearchIntegrationNumber] = useState("");
  const [searchNumber, setSearchNumber] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [searchRoomAddressName, setSearchRoomAddressName] = useState("");
  const [searchObjectIntegrationNumber, setSearchObjectIntegrationNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false);

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "EquipmentList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();
  const [getEquipmentTable, { isLoading, error }] =
    useLazyGetEquipmentListQuery();

  const [getFloorOptions, { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions }] =
    useLazyGetFloorOptionsSelectByFacilityQuery();
  const [printEquipmentList, { errorPrint, isLoadingPrint }] =
    useLazyPrintEquipmentListQuery();

  useEffect(() => {
    let filtersRes = {}
    let res = ""
    if (params) {
      Object.keys(params)?.forEach(key => {
        if (params[key] != "все" && params[key] != "")
          if (key == "equipmentKindId") {
            if (JSON.parse(params[key])?.isType) {
              filtersRes["kindId"] = Number(JSON.parse(params[key]).id.replace("parent", ""))
            } else {
              filtersRes["typeId"] = JSON.parse(params[key]).id
            }
          } else if (key == "lifeTimeDate" || key == "workingCorrectly") {

            filtersRes[key] = params[key] == "true" ? true : false
          } else {
            filtersRes[key] = params[key]
          }

      });
    }
    res = "&" + Object.keys(filtersRes)
      .map((key) => `${key}=${filtersRes[key]}`)
      .join("&")
      changePageNumber(0)
    getEquipmentTable(
      {
        PageNumber: 0,
        filters: res
      });
  }, [params]);

  useEffect(() => {
    let filtersRes = {}
    let res = ""
    if (params) {
      Object.keys(params)?.forEach(key => {
        if (params[key] != "все" && params[key] != "")
          if (key == "equipmentKindId") {
            if (JSON.parse(params[key])?.isType) {
              filtersRes["kindId"] = Number(JSON.parse(params[key]).id.replace("parent", ""))
            } else {
              filtersRes["typeId"] = JSON.parse(params[key]).id
            }
          } else if (key == "explotationExceeded" || key == "workingCorrectly") {
            filtersRes[key] = params[key] == "true" ? true : false
          } else {
            filtersRes[key] = params[key]
          }

      });
    }
    res = "&" + Object.keys(filtersRes)
      .map((key) => `${key}=${filtersRes[key]}`)
      .join("&")

    getEquipmentTable(
      {
        PageNumber: pageNumber,
        filters: res
      });
  }, [pageNumber]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchRoomAddressName}`, searchRoomAddressName);
  }, [searchRoomAddressName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchObjectIntegrationNumber}`, searchObjectIntegrationNumber);
  }, [searchObjectIntegrationNumber, setValue]);


  const facilityId = watch(`${formFields.searchParams}.${formFields.protectionObjectId}`);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchIntegrationNumber}`, searchIntegrationNumber);
  }, [searchIntegrationNumber, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchNumber}`, searchNumber);
  }, [searchNumber, setValue]);


  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchName}`, `${searchName}`);
  }, [searchName, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchPlume}`, `${searchPlume}`);
  }, [searchPlume, setValue]);
  useEffect(() => {
    if (facilityId) {
      if (objectId && !ranOnce2) {
        setRanOnce2(true)
        setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, objectId)
      }
      if (facilityId == "все") {
        setValue(`${formFields.searchParams}.${formFields.floorId}`, "все")
      }
      if (equipmentGroupId) {
        setValue(`${formFields.searchParams}.${formFields.equipmentGroupId}`, equipmentGroupId)
      }
      else {
        getFloorOptions(facilityId).then(
          ({ data, isSuccess }) =>
            isSuccess &&
            !!data?.length &&
            setValue(`${formFields.searchParams}.${formFields.floorId}`, "все")
        );
      }
    }

  }, [facilityId, objectId, equipmentGroupId, getFloorOptions, setValue]);

  const columnsFromStorage = (() => {
    const storageData =
      gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();
  console.log("grid", columnsFromStorage)
  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        searchNumber,
        setSearchNumber,
        searchIntegrationNumber,
        setSearchIntegrationNumber,
        searchRoomAddressName,
        setSearchRoomAddressName,
        searchObjectIntegrationNumber,
        setSearchObjectIntegrationNumber,
        searchName,
        setSearchName,
        searchPlume,
        setSearchPlume,
        objectId
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );
  const rows = useMemo(() => {
    const { data } = equipments;
    return data?.length
      ? data.map(({ id, name, number, jobChart, lifeTimeDate, lefeTimeOutdated, objectIntegrationNumber, roomAddressName, floorNumber, floorComment, workingProperly, lastServiceDate, integrationNumber, plume, protectionObjectId, protectionObjectName, enableForEdit, equipmentKindName }) => {

        return {
          id,
          integrationNumber,
          number,
          objectIntegrationNumber,
          roomAddressName,
          name: {
            id,
            name,
            action: () => handleOpenEquipment(id),
          },
          plume,
          floorNumber: {
            id,
            floorNumber,
            floorComment,
            action: () => handleOpenSchema(id),
          },
          protectionObjectName,
          lifeTimeDate: <Typography sx={{ color: lefeTimeOutdated ? "red" : "black", fontSize: "14px !important" }}>{lifeTimeDate ? dayjs(lifeTimeDate).format(dateFormat) : ""}</Typography>,
          lastServiceDate: lastServiceDate ? <Typography sx={{ color: "green", fontSize: "14px !important" }}>{dayjs(lastServiceDate).format(dateFormat)}</Typography> : <></>,
          workingProperly: workingProperly ? <Typography sx={{ color: "green", fontSize: "14px !important" }}>Да</Typography> : <Typography sx={{ color: "red", fontSize: "14px !important" }}>Нет</Typography>,
          equipmentKindName,
          edit: enableForEdit ? <EditButton handleClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))} /> : <></>,
          tasks: getDataToChart({ jobChart, action: `?facilityName=${protectionObjectId}&equipmentId=${id}&equipmentName=${name}` }),
        };
      })
      : [];
  }, [equipments]);

  const handleOpenSchema = (id) => {
    setOpenSchema(true);
    setSelectedEquipId(id);
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  useEffect(() => {
    if (!ranOnce) {

      setRanOnce(true);
      setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, "все")
      setValue(`${formFields.searchParams}.${formFields.floorId}`, "все")
      setValue(`${formFields.searchParams}.${formFields.equipmentKindId}`, "все")
    }
  }, []);
  const recordsCount = useMemo(
    () => equipments && equipments?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [equipments]
  );
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  const base64toPDF = (name, content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false)
  }

  const printBase64 = (content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blob = new Blob([bufferArray], { type: 'application/pdf' }); //this make the magic
    var blobURL = URL.createObjectURL(blob);

    let iframe = document.createElement('iframe'); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    setPrintLoading(false)
  }

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };


  const printEquipmentListWrapper = (isPrint) => {
    if (isPrint)
      setPrintLoading(true);
    else
      setLoading(true);
    if (params.equipmentKindId == "все")
      delete params.equipmentKindId
    else if (JSON.parse(params.equipmentKindId)?.isType) {
      params["kindId"] = Number(JSON.parse(params.equipmentKindId).id.replace("parent", ""))
    } else {
      params["typeId"] = JSON.parse(params.equipmentKindId).id
    }
    if (params.floorId == "все")
      delete params.floorId
    if (params.protectionObjectId == "все")
      delete params.protectionObjectId
    printEquipmentList({
      ...params,
      pageNumber,
    }).then((data) => {
      const res = data.data
      if (isPrint)
        printBase64(data.data.content)
      else
        base64toPDF(data.data.name, data.data.content)
    });
  }


  return (
    <Box className="template_page">
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => getEquipmentTable({ pageNumber })}

        />
      )}
      {openSchema && (
        <ModalEquipSchema
          open={openSchema}
          setOpen={setOpenSchema}
          control={control}
          setValue={setValue}
          equipId={selectedEquipId}
          refetch={() => getEquipmentTable({ pageNumber })}

        />
      )}
      <Box className="template_page-header">
        <Typography className="title-page">
          {t("title_page:list_equipment")}
        </Typography>
        <Box>
          {printloading ?
            <Box className="full_screen_icon-wrapper">
              <CircularProgress className="full_screen_icon" sx={{
                width: "30px",
                height: "30px",
                marginRight: "20px",
                marginTop: "5px"
              }}
              />
            </Box>
            :
            <Box className="full_screen_icon-wrapper">
              <PrintIcon
                className="full_screen_icon"
                onClick={() => printEquipmentListWrapper(true)}
                sx={{
                  width: "30px",
                  height: "30px",
                  marginRight: "20px",
                  marginTop: "5px",
                  border: "0px !important"
                }}
              />
            </Box>
          }
          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={() => printEquipmentListWrapper(false)}
            disabled={loading}
            sx={{ height: "49px", marginRight: "16.8px" }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : t("button:create_act_pdf")}
          </Button>
          {/*   <Button
                className="btn btn-outlined-primary"
                variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => {
              navigate(PATHS.ADD_EQUIPMENT);
            }}
          >
            {t("button:add_equipment")}
          </Button>
          */}
          <Button
            className="btn btn-outlined-primary magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "49px", marginRight: "16.8px" }}
          >
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => { }}
        withSearchField={false}
        isLoading={isLoading}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={"60vh"}
        maxHeight={"70vh"}
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "EquipmentList" })}
        nameStorage={"EquipmentList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};

export default EquipmentList;
