import React from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { Box, Modal, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import FormItem from "../FormItem";
import { formFields } from '../../pages/Plume/form-config'

export const ModalRoomAddress = ({
  name,
  control,
  open,
  config,
  handleSave,
  setsearchIntNum,
  selectedintNum,
  SetselectedintNum,
  searchIntNum,
  handleCancel,
  plumes,
  appendPlume,
  removePlume,
  updatePlume,
}) => {
  const { t } = useTranslation();

  const address = useWatch({ control, name });
  return (
    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modal_address"
    >
      <Box className="modal_address-body">
        <Box>
          <Box className="modal_address-header_right">
            <Typography className="modal_address-body-title">
              {t("title:enter_address_room")}
            </Typography>
            <IconButton onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="modal_address-body-form" sx={{ overflowY: 'scroll', maxHeight: 250 }}>
            {config.map(({ id, name: subName, label, input, CustomFormItem, ...rest }) => (
              subName == "protectionObjectIntegrNumberAltId" ?
                <FormItem
                  key={id}
                  control={control}
                  name={`${name}.${subName}`}
                  searchIntNum={searchIntNum}
                  setsearchIntNum={setsearchIntNum}
                  value={selectedintNum}
                  onChange={((e) => SetselectedintNum(e.target.value))}
                  label={label}
                  input={input}
                  {...rest}
                /> :
                CustomFormItem ?
                  <CustomFormItem
                    key={id || 'plumesList'}
                    control={control}
                    name={`${name}.${subName}`}
                    setsearchIntNum={setsearchIntNum}
                    searchIntNum={searchIntNum}
                    label={label}
                    input={input}
                    plumes={plumes}
                    appendPlume={appendPlume}
                    removePlume={removePlume}
                    updatePlume={updatePlume}
                    {...rest}
                  />
                  :
                  <FormItem
                    key={id}
                    control={control}
                    name={`${name}.${subName}`}
                   
                    label={label}
                    input={input}
                    {...rest}
                  />
            ))}
          </Box>
        </Box>
        <Box className="modal_address-body-buttons">
          <Button
            className="btn btn-primary-fill"
            onClick={handleSave}
            disabled={!address?.name || !plumes.length || !!plumes.find(el => !el?.name)}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};