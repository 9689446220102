export const getParamsText = ({ coordinates, text="", line }) => {
  const allX = coordinates.filter((e, i) => i % 2 === 0 && e);
  const allY = coordinates.filter((e, i) => i % 2 !== 0 && e);
  const width = Math.max(...allX) - Math.min(...allX);
  const height = 16;
  const positionY = ((Math.max(...allY) - Math.min(...allY)) / 10);
  const x = Math.min(...allX);
  const y = Math.min(...allY) + positionY + height * line;

  return {
    x,
    y,
    width,
    height,
    fontSize: 16,
    fill: "black",
    align: "center",
    fontStyle: "bold",
    text,
  };
};
export const getParamsTextWithScale = ({ coordinates, text="", line,scale =1 }) => {
  const allX = coordinates.filter((e, i) => i % 2 === 0 && e);
  const allY = coordinates.filter((e, i) => i % 2 !== 0 && e);
  const width = Math.max(...allX) - Math.min(...allX);
  const height = scale<1 ? 16 : 16/scale+1;
  const positionY = ((Math.max(...allY) - Math.min(...allY)) / 10);
  const x = Math.min(...allX);
  const y = Math.min(...allY) +positionY + height * line;
  const sscale = scale<1 ? 16 : 16/scale
  const WidthCheck = text.length*sscale < width ? width : 0
  return {
    x,
    y,
    width:WidthCheck,
    height,
    fontSize: sscale,
    fill: "black",
    align: "center",
    fontStyle: "bold",
    text,
  };
};
