import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const tasksApi = createApi({
  reducerPath: "tasks/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ['Tasks'],
  endpoints: (build) => ({
    getTasksList: build.query({
      query: ({ PageNumber = 1,address, ...filters }) => ({
        url: `api/tasks/get-tasks-list?PageNumber=${PageNumber}${address}&${Object.keys(
          filters
        )
          .map((key) => `${key}=${filters[key]}`)
          .join("&")}`,
      }),
      providesTags: ['Tasks'],
    }),
    getTask: build.query({
      query: (taskId) => ({
        url: `api/tasks/get/${taskId}`,
      }),
    }),
    getTaskLazy: build.query({
      query: (taskId) => ({
        url: `api/tasks/get/${taskId}`,
      }),
    }),
    editTask: build.mutation({
      query: (data) => ({
        url: "/api/tasks/edit",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tasks', id: arg.id }]
    }),
    addTask: build.mutation({
      query: (data) => ({
        url: "/api/tasks/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tasks', id: arg.id }]
    }),
    addTaskComment: build.mutation({
      query: (data) => ({
        url: "/api/tasks/add-job-comment",
        method: "POST",
        body: data,
      }),
    }),
    getTaskComments: build.query({
      query: ({jobId,pageNumber}) => ({
        url: `api/tasks/get-job-comments?jobId=${jobId}&pageNumber=${pageNumber}`,
      }),
    }),
    signAndSend: build.mutation({
      query: ({ taskId }) => ({
        url: `api/tasks/sign-and-send`,
        method: "POST",
        body: { taskId },
      }),
    }),
    printTaskList: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/tasks/print-tasks-list?${stringParams}`,
        };
      }
    })
  }),
});

export const {
  useLazyGetTasksListQuery,
  useGetTaskQuery,
  useLazyGetTaskLazyQuery,
  useLazyGetTaskQuery,
  useEditTaskMutation,
  useAddTaskCommentMutation,
  useGetTaskCommentsQuery,
  useLazyGetTaskCommentsQuery,
  useAddTaskMutation,
  useSignAndSendMutation,
  useLazyPrintTaskListQuery
} = tasksApi;
