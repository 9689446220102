import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, Backdrop, CircularProgress, Button } from "@mui/material";

import "../styles/styles.css";
import {
  contentFormConfig,
  formFields,
  STEP,
  stepsParams,
} from "../form-config";

import StepperCustom from "../../../components/StepperCustom";
import FormItem from "../../../components/FormItem";
import ButtonsField from "./ButtonsField";
import FormItemCheckboxControlled from "../../../components/FormItem/FormItemCheckboxControlled";
import ImageSchema from "../ImageSchema";
import { useLazyGetEquipmentReglamentsQuery, useUpdateEquipmentsCoordinatesMutation } from "../../../../middleware/equipmentsApi";
import {
  useAddEquipmentMutation,
  useDeleteEquipmentMutation,
  useUpdateRoomAddressesCoordinatesMutation,
  useLazyGetLastNumberandPrefixQuery,
  useValidateEquipmentNumbersMutation
} from "../../../../middleware/equipmentsApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useAction } from "../../../../hooks/actions";
import { getRequestData } from "./helpers";
import { getRequestDataFilesForAdd } from "./helpers";
import { getEquipmentsCoordinatesWithRoomId } from "../../../../helpers/helper";
import { useGetFacFireCompSelectElementsQuery } from "../../../../middleware/facilitiesApi";
import useScreenSize from "../../../../hooks/useScreenSize";
import { PATHS, screenSizes } from "../../../../constants";
import {
  getEquipmentTypeIdOptionsGroup,
  getFacilityAndFireCompartmentOptionsGroup,
} from "../helpers";
import AddEquipmentLiteTree from '../AddEquipmentLiteTree'
import AddEquipmentModal from './AddEquipmentModal'
import EditReglamentModal from '../AddEquipmentLiteTree/EditReglamentModal'
import { useNavigate } from 'react-router-dom'

const AddEquipmentPage = ({ isLite }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsErrorSubmit, setIsSuccessSubmit, setErrorNotificationParams } =
    useAction();

  const [getEquipmentReglaments] = useLazyGetEquipmentReglamentsQuery();
  const screenSize = useScreenSize();
  const isActive = true; // mock data. Wait from backend

  const [turnOnEdit, setTurnOnEdit] = useState(false);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState('')
  const [activeStep, setActiveStep] = useState(STEP.DEFAULT);
  const [objectId, setObjectId] = useState("");
  const [deletedAddress, setdeletedAddress] = useState([]);
  const [newRoomAddresses, setNewRoomAddresses] = useState([]);
  const [equipmentCoordinatesChange, setequipmentCoordinatesChange] = useState([]);
  const [addplume, setaddplume] = useState(false);
  const [editReglamentOpen, setEditReglamentOpen] = useState(false);
  const [xyGroup, setxyGroup] = useState([]);
  const [ranOnce, setRanOnce] = useState(false);
  const [regulations, setRegulations] = useState([]);
  const { label: currentStepLabel } =
    stepsParams.find(({ step }) => step === activeStep) || {};
  const [
    updateRoomAddressesCoordinates,
    {
      error: errorUpdateRoom,
      isSuccess: isSuccessUpdateRoom,
      isLoading,
      isError: isErrorUpdateRoom,
    },
  ] = useUpdateRoomAddressesCoordinatesMutation();
  const [updateEquipmentCoordinates, {
    isSuccess: isSuccessUpdateEquipment,
    isLoading: isLoadingUpdateEquipment,
    isError: isErrorUpdateEquipment
  }] = useUpdateEquipmentsCoordinatesMutation();

  const isError =   isErrorUpdateRoom || isErrorUpdateEquipment;
  const isSuccess = ( isSuccessUpdateRoom) || (isSuccessUpdateEquipment && isSuccessUpdateRoom);
  const isLoadingRequest = isLoading || isLoadingUpdateEquipment;

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    unregister,
    setValue,
    watch,
    getValues,
    trigger,
    reset,
    resetField,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.regulations]: [],
      [formFields.havingServiceContract]: true,
    },
  });

  const object = watch(formFields.generalInformation.fireCompartmentId);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleSubmitLite = (data) => {
    const {
      floor: floorInfoId,
      roomAddresses,
      equipmentsCoordinates,
    } = data || {};

    const newArray = roomAddresses.map(item => {
      item.protectionObjectIntegrNumberAltId = item.protectionObjectIntegrNumberAltId == '' ? null : item.protectionObjectIntegrNumberAltId;
      item.plumes = typeof item.plumes[0] === 'string' ? item.plumes : item.plumes.map(el => el.name);
      const foundItem = xyGroup.find(i => i.id === item.id);
      if (foundItem) {
        return {
          ...item, coordinates: item.coordinates.sort((a, b) => a.order - b.order).map((res, index) => {
            return { ...res, coordinateX: ((res.coordinateX * foundItem.scaleX + (foundItem.x / foundItem.scaleRoom.x))), coordinateY: ((res.coordinateY * foundItem.scaleY + (foundItem.y / foundItem.scaleRoom.y))) }
          })
        };
      } else {
        return item;
      }
    });
    
    updateRoomAddressesCoordinates({ floorInfoId,roomAddressesIdsToDelete:deletedAddress?.map(obj => obj.id), roomAddresses: newArray }).then(
      ({ data, error }) => {
        setValue(formFields.roomAddresses,data)
          const equipChange = equipmentsCoordinates.filter(obj => equipmentCoordinatesChange.includes(obj.id))
          console.log("equipChange",equipChange)
          const requestData = getEquipmentsCoordinatesWithRoomId({
            equipmentsCoordinates:equipChange,
            rooms: data,
          });

          if (!error) {
            const data = requestData.map(
              ({
                id,
                floorSchemeCoordinateX,
                floorSchemeCoordinateY,
                roomAddressId,
                floorInfoId,
                typeId,
                calculateRoomAddressId
              }) => ({
                id,
                floorSchemeCoordinateX,
                floorSchemeCoordinateY,
                roomAddressId,
                floorInfoId,
                typeId,
                calculateRoomAddressId
              })
            );
            console.log("equipmentCoordinatesChange",data)
            data?.length && updateEquipmentCoordinates(data);
          }
          setNewRoomAddresses([])
        }
    );
  }

  useEffect(() => {
    errorUpdateRoom?.originalStatus === 409
      ? setErrorNotificationParams({
        isOpen: true,
        title: t("modal_error_plume:title"),
        content: t("modal_error_plume:text"),
      })
      : setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const isDisabled = !!errors[currentStepLabel];

  
  return (
    <Box className="addEquipment">
      <Box>
        <Box className="addEquipment-header">
          <Typography className="title-page">
            {t(isLite ? "title_page:add_equipment_lite" : "title_page:add_equipment")}
          </Typography>
        </Box>

      </Box>
      <Box className="addEquipment-footer">
            <>
               <Button
                form="myform"
                className="btn btn-primary-fill"
                type="submit"
                disabled={addplume || turnOnEdit}
              >
                {t("button:save")}
              </Button>
            </>
      </Box>


      <Box className="addEquipment-form">
        
          
              <>
               
                <EditReglamentModal
                  selectedEquipmentGroup={selectedEquipmentGroup}
                  isOpen={editReglamentOpen}
                  onClose={() => setEditReglamentOpen(false)}
                />
                <AddEquipmentLiteTree
                  turnOnEdit={turnOnEdit}
                  setTurnOnEdit={setTurnOnEdit}
                  onOpenEditReglament={(id) => {
                    setSelectedEquipmentGroup(id)
                    setEditReglamentOpen(true)
                  }}
                  register={register}
                  unregister={unregister}
                  handleSubmit={handleSubmit}
                  trigger={trigger}
                  isActive={isActive}
                  addplume={addplume}
                  setaddplume={setaddplume}
                  setdeletedAddress={setdeletedAddress}
                  setError={setError}
                  handleSubmitLite={handleSubmitLite}
                  setNewRoomAddresses={setNewRoomAddresses}
                  newRoomAddresses={newRoomAddresses}
                  regulations={regulations}
                  isSuccess={isSuccess}
                  xyGroup={xyGroup}
                    setxyGroup={setxyGroup}
                  setSelectedEquipmentGroup={setSelectedEquipmentGroup}
                  setequipmentCoordinatesChange={setequipmentCoordinatesChange}
                  equipmentCoordinatesChange={equipmentCoordinatesChange}
                  control={control}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  resetField={resetField}
                  objectId={objectId}
                />
              </>
            
    
      </Box>
      <Backdrop
        sx={{ color: "var(--white)", zIndex: 9999 }}
        open={isLoadingRequest}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default AddEquipmentPage;
