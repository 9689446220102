import * as React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { styled } from '@mui/material/styles';
import FormItem from "../../components/FormItem";
import { useForm } from "react-hook-form";
import "./styles/style.css";
import InputText from "../../components/InputText";
import Search from "../Search";
import DonutChart from "../DonutChart";
import { withStyles } from '@mui/styles';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Backdrop,
  CircularProgress, IconButton,
  Tooltip, tooltipClasses, TextField
} from "@mui/material";
import TaskStateList from "../TaskStateList/TaskStateList";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { globalFields } from "../../../constants";
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const TableCellItem = ({
  mode = null,
  data,
  value,
  floorModalHandler,
  sign = false,
  color,
}) => {
  const navigate = useNavigate();

  switch (mode) {
    case "diagram":
      const { tasks } = data || {};
      const taskstates = tasks?.filter((task) => task.name != "totalJobsAmount" && task.name != "total_jobs_amount")
     
      const text = tasks?.filter((task) => task.name == "totalJobsAmount" || task.name == "total_jobs_amount")[0]?.value
      const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'var(--white)',
          p: '10px'
        },
      }));

      return (
        <HtmlTooltip
          arrow
          placement="left-start"
          title={
            <TaskStateList
              data={taskstates || []}
              fontSize="12px"
              lineHeight="18px"
              height="8px"
              width="8px"
            />
          }
        >
          <Box>
            <DonutChart
              data={taskstates || []}
              width={32}
              height={32}
              radius={16}
              thickness={4}
              isText={text || "0"}
            />
          </Box>
        </HtmlTooltip>
      );
    case "list":
      return (
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {value.map(({ floorId, floor }, i) => {
            return (
              <li
                key={floorId}
                style={{
                  color: color ? color : null,
                  marginTop: i !== 0 ? "8px" : null,
                }}
                onClick={() => floorModalHandler(floorId)}
              >
                {floor}
              </li>
            );
          })}
        </ul>
      );
    case "linkedList":
      return (
        <List>
          {value.map(({ cellText, link }, i) => {
            return (
              <ListItem key={i} sx={{ p: 0 }}>
                <ListItemButton
                  className="table-row-cell-link"
                  sx={{ verticalAlign: "initial", pt: 0, pb: 0 }}
                  onClick={() => navigate(link)}
                >
                  <ListItemText primary={cellText} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      );
    case "workList":
      return (
        <List>
          {value.map(({ id, description, name }) => {
            return (
              <ListItem key={id} sx={{ p: 0 }}>
                <ListItemText primary={description || name} />
              </ListItem>
            );
          })}
        </List>
      );
    case "simpleList":
      return (
        <List>
          {value.map((item, i) => {
            return (
              <ListItem key={i} sx={{ p: 0 }}>
                <ListItemText primary={item} />
              </ListItem>
            );
          })}
        </List>
      );
    case "sign":
      return (
        value && (
          <Box sx={{ height: "30px" }}>
            <img style={{ height: "30px" }} alt="sign" src="/sign.png" />
          </Box>
        )
      );
    default:
      return (
        <Box>
          <Box sx={{ color: color ? color : null }}>{value}</Box>
        </Box>
      );
  }
};

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="table-head">
      <TableRow>
        {columns.map(
          ({
            id,
            numeric,
            enableSorting,
            label,
            size,
            HeaderCell,
            textCenter = false,
            isSticky,
          }) => (
            <TableCell
              key={id}
              align={textCenter ? "center" : numeric ? "right" : "left"}
              sortDirection={orderBy === id ? order : false}
              className={`table-head-cell ${isSticky ? "sticky" : ""}`}
              sx={{
                minWidth: size,
                padding: "10px",
              }}
            >
              {enableSorting ? (
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : "asc"}
                  onClick={createSortHandler(id)}
                  IconComponent={UnfoldMoreIcon}
                >
                  {label}
                </TableSortLabel>
              ) : HeaderCell ? (
                <HeaderCell />
              ) : (
                label
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

const BorderlessTableCell = (props) => {
  // Предположим, что у вас есть переменная shouldApplyStyles, определяющая, когда нужно применять стили
  const shouldApplyStyles = props.shouldApplyStyles;

  const StyledTableCell = shouldApplyStyles ? withStyles(theme => ({
    root: {
      borderTop: props => `1px solid ${props.border || 'black'}`,
      borderBottom: "none", // Установите стиль и цвет границы ячейки
      borderLeft: props  => props.colIndex == 0  ? `1px solid ${props.border || 'black'}` : "none",
      borderRight: props  => props.colIndex == props.length-1  ? `1px solid ${props.border || 'black'}` : "none",
    },
  }))(TableCell) : TableCell;

  return <StyledTableCell {...props} />;
}
const TableBasic = ({
  columns,
  rows,
  handleClickCell = null,
  searchValue,
  floorModalHandler = null,
  changeSearchValue = () => { },
  pageNumber,
  changePageNumber = () => { },
  placeholder,
  rowsPerPageOptions = 15,
  isLoading,
  withSearchField = true,
  isPagination = true,
  count,
  getCustomBgColorRow,
  getCustomBorderRow,
  minHeight = "55vh",
  maxHeight = "60vh",
}) => {

  const { t } = useTranslation();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [searchPage, setsearchPage] = React.useState(pageNumber);
  const { rowsPerPage } = useAppSelector((state) => state.settings);
  const { changeRowsPerPage } = useAction();
  const { control, setValue } = useForm();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    changePageNumber(newPage);
    console.log("changePageNumber", newPage)
    //setValue("paginationTextField", newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    changeRowsPerPage(parseInt(event.target.value, 10));
    changePageNumber(0);
  };

  useEffect(() => {
    changePageNumber(pageNumber);
   // console.log("changePageNumber", pageNumber)
    setValue("paginationTextField", pageNumber+1)
  }, [rows]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => changePageNumber(0), []);

  const handleLastPageButtonClick = () => {
    const newPage = Math.max(0, Math.ceil(count / 15) - 1);
    changePageNumber(newPage);
    setValue("paginationTextField", Number(newPage) + 1)
  };
  const handleFirstPageButtonClick = () => {
    changePageNumber(0);
    setValue("paginationTextField", 1)
  };
  const handlePrevPageButtonClick = () => {
    changePageNumber((prev) => Number(prev) - 1);
    setValue("paginationTextField", Number(pageNumber))
  };
  const handleNextPageButtonClick = () => {
    changePageNumber((prev) => Number(prev) + 1);
    setValue("paginationTextField", Number(pageNumber) + 2)
  };
  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      if (Number(event.target.value) + 2 > Math.max(0, Math.ceil(count / 15) - 1)) {
        changePageNumber(Math.max(0, Math.ceil(count / 15) - 1));
        setValue("paginationTextField", Math.max(0, Math.ceil(count / 15)))
      }
      else if (event.target.value == "" || event.target.value == "0") {
        changePageNumber(0);
        setValue("paginationTextField", 1)
      }
      else
        changePageNumber(Number(event.target.value) - 1);
    } else {
      if (event.key === "e" || event.key === "E" || event.key === "*" || event.key === "/" || event.key === "-" || event.key === "+" || event.key === "." || event.key === ",") {
        event.preventDefault()
      } else {
        const input = event.target.value;
        const filteredInput = input.replace(/[^\d]/g, '').slice(0, 4);
        setValue("paginationTextField", filteredInput)
      }

    }
  };
  return (
    <Box className="table_field">
      {withSearchField && (
        <Search
          changeSearchValue={changeSearchValue}
          searchValue={searchValue}
          placeholder={placeholder}
        />
      )}
      <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className="paper" sx={{ mb: 2 }}>
        <TableContainer sx={{ borderRadius: "2px 2px", maxHeight: () => (window.innerHeight > 715 ? maxHeight : minHeight) }}>
          <Table
            sx={{ minWidth: 750 }}
            className="table"
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <EnhancedTableHead
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {!isLoading && (
              <TableBody className="table-body">
                {stableSort(rows, getComparator(order, orderBy)).map(
                  (row, index) => (
                    <TableRow key={index} hover tabIndex={-1}>
                      {columns.map(
                        ({
                          id,
                          clickable,
                          mode = null,
                          color = null,
                          styleTableCell,
                          sign = false,
                          isSticky,
                          RowCell,
                        },colIndex) => (
                          <BorderlessTableCell
                            key={id}
                            shouldApplyStyles={getCustomBorderRow ? true : false}
                            border={getCustomBorderRow ? getCustomBorderRow(row, index) : ""}
                            colIndex={colIndex}
                            length={columns?.length}
                            className={`table-row-cell ${isSticky ? "sticky" : ""
                              }`}
                            onClick={(event) =>
                              clickable && handleClickCell(event, row.id)
                            }
                            sx={{
                              cursor: clickable ? "pointer" : "default",
                             // border: getCustomBorderRow ? "4px solid "+getCustomBorderRow(row, index) : 0,
                              background: getCustomBgColorRow
                                ? getCustomBgColorRow(row, index)
                                : index % 2
                                  ? "var(--bgTableRow)"
                                  : "var(--bgWhite)",
                              ...styleTableCell,
                            }}
                          >
                            {RowCell ? (
                              <RowCell
                                id={row.id}
                                value={row[id]}
                                item={row[id]}
                                row={row}
                                index={index}
                              />
                            ) : (
                              <TableCellItem
                                data={row}
                                mode={mode}
                                sign={sign}
                                value={row[id]}
                                floorModalHandler={floorModalHandler}
                                color={color}
                              />
                            )}
                          </BorderlessTableCell>
                        )
                      )}
                    </TableRow>
                  )
                )}
                {rows?.length === 0 && (
                  <TableRow
                    style={{
                      height: 56,
                    }}
                  >
                    <TableCell
                      sx={{ textAlign: "center" }}
                      colSpan={columns?.length}
                    >
                      {t("table:empty_result")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!!rows?.length && isPagination && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={count || rows.length}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("table:labelRowsPerPage")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from > to ? to : from}–${to} из ${count !== -1 ? count : `больше чем ${to}`}`
            }
            ActionsComponent={(subprops) => {
              return (
                <Box sx={{ display: "flex" }}>
                  <IconButton
                    onClick={handleFirstPageButtonClick}
                    aria-label="last page"
                    sx={{height:"40px",marginTop:"10px"}}
                    disabled={pageNumber === 0}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={handlePrevPageButtonClick}
                    sx={{height:"40px",marginTop:"10px"}}
                    disabled={pageNumber === 0}
                    aria-label="last page"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <FormItem
                    control={control}
                    name={`paginationTextField`}
                    placeholder="Страница"
                    size="small"
                    defaultValue={1}
                    sx={{ width: "100px" }}
                    type={"number"}
                    InputProps={{ type: "number" }}
                    sx={{
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield'
                      },
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0
                      }
                    }}
                    input={(props) => (
                      <InputText
                        onKeyPress={handleKeyPress}

                        {...props}
                      />
                    )}
                  />

                  <IconButton
                    onClick={handleNextPageButtonClick}
                    sx={{height:"40px",marginTop:"10px"}}
                    disabled={pageNumber === Math.max(0, Math.ceil(count / 15) - 1)}
                    aria-label="last page"
                  >
                    <NavigateNextIcon />
                  </IconButton>
                  <IconButton
                    onClick={handleLastPageButtonClick}
                    sx={{height:"40px",marginTop:"10px"}}
                    disabled={pageNumber === Math.max(0, Math.ceil(count / 15) - 1)}
                    aria-label="last page"
                  >
                    <LastPageIcon />
                  </IconButton>
                </Box>
              );
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default TableBasic;
